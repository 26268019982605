import { Container } from "react-bootstrap"
import { Link } from "react-router-dom"

export default function AboutUs() {
    return (
        <Container className="my3 py-5">
         <h1>About Us</h1>  
<p>The therapists at The Attachment and Trauma Center of Missouri share a commitment to providing hope and healing for children, adults, and families.  We provide specialized expertise in the treatment of attachment and trauma issues not available elsewhere in our region.
        
    </p>
    {/* <p>

Jane Rickard, LCSW, and A. J. Anderson, LPC, have been treating issues of attachment and trauma for over 20 years.  Their training has included working alongside nationally recognized, first-generation attachment therapists.  As well as extensive training in the field of trauma and attachment with other contemporary experts, focusing on the area of trauma and its effect on the brain and body.  Therapists at ATC are trained in the use of EMDR with children and adults.</p> */}

<p>We are a group of licensed mental health providers with diverse backgrounds.  Our diverse backgrounds allow us to provide comprehensive treatment for our clients.  And while we are diverse in our backbackgrounds, we share a belief system and collaborative approach to treatment and training.  At ATC, our therapists are each unique, just like our clients, and we use our differences to best meet each individual's needs.  We offer treatment options tailored to meet the needs of each child, adult, and family.</p>

<p>
See our <Link to="/services">Services Page</Link> for additional info.
</p>

<p>We recognize that symptoms in body, mind, or emotion call to us as opportunities for change.  Our philosophy is to support each person's unique movement toward healing and growth.</p>

<p>Please <Link to="/contact">Contact Us</Link> for more informaton.</p>

<p>ATC also offers opportunities for those needing supervision for their licensure.  So, at times, provisionally licensed clinicians may sit in on sessions or be a part of treatment with permission of our clients.</p>

        </Container>
    )
}
