import { Container } from "react-bootstrap";

export default function AttachmentDisorder() {
    return (
        <>
        <Container className="my-3 py-5">
            
<h1>Attachment and Trauma</h1>
<p className="fs-4">Providing expertise and specialized treatment for disupted attachment and trauma is what we do.
</p>
<p>
    
Since you've come upon this site, you probably have someone in your life that you love and from whom you want to feel love, or you may be looking for ways to heal yourself.  Your child or adult loved-one may have been given the diagnosis of Reactive of Attachment Disorder, DMDD, ODD, or Posttraumatic Stress Disorder (PTSD), along with a host of other diagnoses, and you're not sure what to do now.
</p>
<p className="fw-bold"> 
At ATC, we understand and can help.</p>

<h5>What you may be experiencing...</h5>
<ul>
    <li>
    clingy and demanding or distant in relationships
    </li>
    <li>
    stressed & anxious
    </li>
    <li>
    like they can't have fun anymore
    </li>
    <li>
    "done"
    </li>
    <li>
    inadequate as parents
    </li>
    <li>
    angry
    </li>
    <li>
    misunderstood and not supported, even by the professionals from whom they seek help
    </li>
</ul>

<h5>Our Approach:</h5>
<ul>
    <li>
    For Children, Integrative Treatment for Trauma, utilizing a combination of family therapy and EMDR trauma therapy
    </li>
    <li>
    For Adults, trauma-informed individual or family therapy, EMDR, with the goal of resolving trauma, building relationsips, and life skills
    </li>
    <li>
    Treatment Team Family Therapy Intensive
    </li>
    <li>
    For Caregivers, Integrative Parenting Class
    </li>
</ul>
        </Container>

        <Container fluid className="bg-2 p-5 m-0 text-white">


<p className="fs-5">It is of the upmost importance for parents/caregivers to be in every session with the child client.  They are the most important people in the lives of their children and MUST be included for healing to occur.</p>

        </Container>

        </>
    )
}
