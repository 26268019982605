import { Container, Row, Col, Card } from "react-bootstrap"
import { Link } from "react-router-dom"

export default function Services() {
    return (
        <>
        <Container className="my-3">
            <h1>services</h1>

            <p>Every situation is unique. Every individual, family, and child is unique.  We have a variety of treatment options to best fit your needs.</p>
<Row>
    <Col md={6} className="my-1">
            <Card className="p-4 bg-1 text-white h-100">

            <h4>Children/Adolescents:</h4>
            <ul>
                <li>Integrative Treatment for Attachment in Children, utilizing a combination of family therapy and EMDR</li>
                <li>For Caregivers, Integrative Parenting</li>
                <li>EMDR</li>
                <li>Family Therapy</li>
                <li>Treatment Groups</li>
            </ul>
            </Card>
    </Col>
    <Col className="my-1">
    <Card className="p-4 bg-2 text-white h-100">

<h5>Treatment for:  Attachment Issues</h5>
<ul>
    <li>Trauma (Chronic/Single)</li>
    <li>Behavioral Problems</li>
    <li>Anxiety</li>
    <li>Depression</li>
    <li>Loss and Grief</li>
</ul>
    </Card>
    </Col>
</Row>

<Row>
    <Col md={6} className="my-1">
        <Card className="p-4 bg-2 text-white h-100">
            <Col md={6}>
<h4>Adults:</h4>
<ul>
    <li>EMDR</li>
    <li>Family Therapy</li>
    <li>Individual Therapy</li>
</ul>
            </Col>
        </Card>
    </Col>
    <Col className="my-1">
        <Card className="p-4 bg-1 text-white h-100">
<h5>Treatment for:</h5>
<ul>
    <li>Attachment Issues</li>
    <li>Trauma</li>
    <li>Behavioral Problems</li>
    <li>Anxiety</li>
    <li>Depression</li>
    <li>Loss and Grief</li>
    <li>Personality Disorders</li>
    <li>Mood Disorders</li>
</ul>
        </Card>
    </Col>
</Row>
        </Container>
<Container>
<p><Link to="/contact">Contact Us</Link> to set up an assessment.</p>
</Container>

</>
    )
}
