import { Container, Row, Col, Image } from "react-bootstrap"
// import VectorDad from "../components/VectorDad"
// import VectorMom from "../components/VectorMom"

export default function Home() {
    return (
        <>

        <Container fluid className="p-5 m-0 bg-2 text-light home1" >
               <Row className="d-flex justify-content-center p-2">
            {/* <Col md={6}>

            <Image pointerEvents="none" onContextMenu="return false;" fluid src={require('../img/artem-kniaz-DqgMHzeio7g-unsplash.jpg')} />


           
            </Col> */}
  

            {/* <div style={{maxWidth:"400px"}}>
           <VectorDad />
        </div> */}

            <Col md={8} className="d-flex align-items-center justify-content-center">
            <p className="fs-5 fw-bold"
        // style={{color:"var(--c2)"}}
        >
         Specialized Therapeutic Services for Children, Adults, and Families dealing with issues of Attachment Trauma, as well as Trauma experienced outside of the Caregiving System
            </p>
</Col>


            </Row> 
            </Container>
           

           <Container fluid className="bg-3 m-0 py-5 px-5">
            
            <p className="fs-4 p-5">Life is made up of relationships. From close relationships with family and friends to mere acquaintances, these series of relationships affect everything we do.</p>
           </Container>




           <Container fluid className="p-5 bg-1"
        //    style={{backgroundImage:"url(https://images.unsplash.com/photo-1461709444300-a6217cec3dff?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=873&q=80)"
        // }}
        >
               <Row>

                   <Col md={6} className="text-white">
               <p className="fs-4">For foster and adopted children,  and sometimes even biological children, early relationships were traumatic and  damaging. This can set up a lifetime of mistrust in future relationships.</p>
       
       <p><b>The Attachment and Trauma Center of Missouri</b> specializes in helping families heal past hurts and create trusting relationships. </p>
                   </Col>
                   <Col>
        <Image pointerEvents="none" onContextMenu="return false;" fluid src={require('../img/max-goncharov-E77RZ6HJ0i8-unsplash.jpg')} />
        <p style={{fontSize:".75rem"}}>
            
        Photo by <a href="https://unsplash.com/@gmax?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Max Goncharov</a> on <a href="https://unsplash.com/?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
  
        </p>
           {/* <VectorMom /> */}
                   </Col>
               </Row>
           </Container>

           
           <Container className="py-5">

            <h4>Services Provided:</h4> 

Evaluation & Consultation
Attachment & Bonding Assessments
Treatment Planning
Integrative Family & EMDR Therapy
EMDR/Trauma Therapy for Adults
Family Therapy
Groups
Integrative Parenting Class
Workshops & Training
           </Container>
        </>
    )
}
