import { Container } from "react-bootstrap"

export default function NotFound() {
    return (
        <Container style={{minHeight:"50vh"}} className="d-flex align-items-center justify-content-center">
          
          <h5>
                Page not found
              </h5>
        </Container>
    )
}
