import { Container } from "react-bootstrap";
import Brain from "../art/Brain";
import { Link } from "react-router-dom";

export default function Trauma() {
    return (
        <Container className="my-3">
            
<h1>Trauma</h1>
<p>Can you remember an event in your life in your life when your heart pounded, your pulse raced, your breathing was rapid and shallow? Both positive and negative events in our lives cause these physiological reactions. Unfortunately negative events tend to leave a lasting impression on our brain. 

</p>

<h5>The Brain & Trauma</h5>

<p>The brain is an amazing instrument capable of infinite processes. However, sometimes in its attempt to protect us, it can actually cause us harm. Our brains are designed to enact certain systems to react to different situations.
    </p>
    <p>
        

Have you ever heard a song or smelled something pleasant that took you back to a fond memory.  Maybe for just a second, your heart skipped a beat, or a smile came to your lips? It was as if you were in that time again, even if for just a brief moment. Our brain is equipped with systems that keep track of not only the emotional feelings of events in our lives, but the accompanying physical reactions as well.
</p>
<p>
    
The system charged with protecting us during traumatic events is called the Limbic System. The limbic system triggers one of three responses: Fight, Flight, or Freeze.  Fight as it implies triggers the chemicals in the body to fight back against the danger. Flight on the other hand gathers all the energy in the body to get away from the situation as fast and efficiently as possible. Freeze happens when the brain perceives that neither of the other options is possible. The body shuts all but the most important functions down. Freeze generally occurs only in conditions where death is the eventual outcome.</p>
<div style={{width:'100px'}}>
<Brain />
</div>
<p>Fight and Flight are the most common responses to traumatic events. When the event is severe enough, the event may make a lasting impression on the brain.  Events, which may have nothing to do with the original trauma, may trigger the brain to react in the same way it did during the original trauma.</p>
<p>Please <Link to="/contact">Contact Us</Link> for information regarding trauma and how we can help</p>


        </Container>
    )
}
