// import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";

export default function MenuMain() {
  return (
    <Navbar variant="dark" expand="lg" className="fw-bold" style={{backgroundColor:"var(--c1)"}}>

<Navbar.Toggle aria-controls="basic-navbar-nav" />

<Navbar.Collapse id="basic-navbar-nav">
<Nav className="me-auto">
<Nav.Link as={Link} to="/">Home</Nav.Link>
<Nav.Link as={Link} to="/services">Services</Nav.Link>
<Nav.Link as={Link} to="/contact">Contact</Nav.Link>
      {/* {menuItems && menuItems.map(({to, name}) => <Link key={to} className="btn fw-bold" style={{color:"white"}} href={to}>{name}</Link> )} */}
</Nav>
</Navbar.Collapse>
    </Navbar>
  );
}

// const menuItems = [
//   { to: "/", name: "Home" },
//   {
//     to: "/about",
//     name: "About",
//   },
//   {
//     to: "/attachment-disorder",
//     name: "Attachment Disorder",
//   },
//   {
//     to: "/services",
//     name: "Services",
//   },
//   {
//     to: "/programs-and-training",
//     name: "Programs and Training",
//   },
//   {
//     to: "/contact",
//     name: "Contact Us",
//   },
// ];
