import { Container } from "react-bootstrap";


export default function Contact() {


  return (
    <Container className="my-3 p-5 d-flex flex-column align-items-center">
      <h1>Contact</h1>
      <p>
      1340 W. Battlefield Road
      <br />
      Ste. 6<br />
      Springfield, MO 65807
      </p>

      <p className="fw-bold fs-5">
      Call 417.881.7151
      </p>
      <p className="fw-bold">
      or email atcmoaj@gmail.com
      </p>

      <a href="mailto:atcmoaj@gmail.com">Click to Send an Email</a>
    </Container>
  );
}
