import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
// import Logo from "./Logo";
export default function Footer() {
    return (
        <Container fluid className="bg-dark p-4 text-white"
        style={{backgroundColor:"var(--c1)"}}
        >
            
<Row>
    <Col md={6} className="d-flex flex-column px-5 pb-4 align-items-center">

    {menuItems && menuItems.map(({to, name}) => <Link key={to} className="link-light text-decoration-none lh-lg" to={to}>{name}</Link> )}

    </Col>

    <Col className="px-5 pb-4 text-center
    d-flex flex-column align-items-center
    ">

    <div className="mb-3"
    style={{width:"50px"}}
    >
    {/* <Logo /> */}
    
    </div>

<small>
<strong>
            Attachment and Trauma Center of Missouri <br />
</strong>
1340 W. Battlefield Road<br />
Ste. 6<br />
Springfield, MO 65807<br />
ph: 417.881.7151<br />
atcmoaj@gmail.com
</small>
    </Col>
</Row>


        </Container>
    )
}


const menuItems = [
    { to: "/", name: "Home" },
    {
      to: "/about",
      name: "About",
    },
    {
      to: "/attachment-disorder",
      name: "Attachment Disorder",
    },
    {
        to: "/trauma",
        name: "Trauma"
    },
    {
      to: "/services",
      name: "Services",
    },
    {
      to: "/contact",
      name: "Contact Us",
    },
    // {
    //   to: "/programs-and-training",
    //   name: "Programs and Training",
    // },
  ];
