import { Route, Routes, BrowserRouter as Router } from "react-router-dom";

import { Container } from "react-bootstrap";
// import { useLocation } from "react-router-dom";

import "./App.css";
// import Logo from "./components/Logo";

import Home from "./pages/Home";
import MenuMain from "./components/MenuMain";
import NotFound from "./pages/NotFound";
import Services from "./pages/Services";
import AboutUs from "./pages/AboutUs";
import Contact from "./pages/Contact";
import Footer from "./components/Footer";
import Trauma from "./pages/Trauma";
import AttachmentDisorder from "./pages/AttachmentDisorder";
import ReactGA from "react-ga4";

function App() {
  ReactGA.initialize("G-LLRQ36HKYN");

  ReactGA.send({ hittype: "pageview", page: window.location.pathname });

  return (
    <Container fluid className="p-0 m-0">
      <Router>
        <MenuMain />

        <Container className="text-center d-flex flex-column justify-content-center p-5 text-center"
                 style={{ color: "var(--c2)" }}>
          <h1 style={{ textTransform: "uppercase" }} className="fs-2">
            <strong>
              Attachment
              <span style={{ fontSize: ".75rem" }} className="mx-2">
                and
              </span>
            </strong>
          </h1>
          <h1
            style={{ textTransform: "uppercase", lineHeight: ".5rem" }}
            className="fs-2"
          >
            <strong>Trauma Center</strong>
          </h1>

          <h2 className="text-uppercase fw-bold fs-6"
                   style={{ textTransform: "uppercase", lineHeight: "1.75rem" }}
                   >of Missouri</h2>

          <p
            className="text-center"
            style={{
              fontSize: "1.1rem",
              lineHeight: "2rem",
              textTransform: "uppercase",
            }}
          >
            Hope and healing for children, adults, and families.
          </p>
        </Container>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/trauma" element={<Trauma />} />
          <Route path="/attachment-disorder" element={<AttachmentDisorder />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </Router>
    </Container>
  );
}

export default App;
